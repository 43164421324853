/*******************************
        Global Overrides
*******************************/


h1, .h1 {
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-size: 25px;
  font-weight: 400;
  line-height: 1.3;
  text-transform: uppercase;
}

h2, .h2 {
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.3;
}

h3, .h3 {
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-size: 19px;
  font-weight: 600;
  line-height: 1.3;
}

h4, .h4 {
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3;
}

h5, .h5 {
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.3;
}

h6, .h6 {
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3;
}


