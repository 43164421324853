/*******************************
             Reset
*******************************/

.ui.dimmer.active {
  z-index: 1;
}

.ui.mini.pagination.pointing.secondary.menu {
  border-bottom: unset;
  display: flex;
  justify-content: end;
}

