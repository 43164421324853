/*******************************
         Theme Overrides
*******************************/

.ui.button {
  padding: 10px;
  margin: 0;
  border-radius: 6px;
  background-color: @blue;
  color: @white;
  font-family: "Open Sans", sans-serif;
  font-size: 13.5px;
  font-weight: 400;
  line-height: 19px !important;
  transition: 300ms;

  svg {
    transition: color 300ms;
  }

  &.no-content .icon {
    margin: 0 !important;
  }

  &:hover {
    color: @white;
    background-color: #6C84A6;

    .fa-trash-can {
      color: @ref-color-red;
    }
  }
}

.ui.button.buttonLink {
  text-transform: none;
  cursor: pointer;
  transition: color 300ms;
  background: transparent;
  color: @blue;
  font-size: 1.05rem;
  font-weight: 300;
  line-height: 1.7;
  margin: 0;
  text-align: inherit;

  .icon {
    height: auto;
  }

  svg,
  .icon {
    margin: 0 0 0 5px !important;
  }


  &:hover {
    color: @ref-color-active;
  }
}

.ui.button.small {
  font-size: 12px;
  text-transform: unset;
}

.ui.button.tiny {
  opacity: 1;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  padding: 4px 10px;
  text-align: center;
  min-width: 110px;
  text-transform: none;
  border-radius: 6px;
  border: 1px solid @blue;
}

.ui.button.primary {
  background-color: @blue;

  &:hover,
  &:focus {
    background-color: #0277CC;
  }
}

.ui.button.disabled:not(.buttonLink) {
  background-color: #bababa;
  color: white;
  border-color: #bababa;
}

ui.button.secondary.disabled {
  background-color: transparent !important;
  border-color: @lightGrey !important;
  color: @white !important;
}

.ui.button.secondary {
  background-color: transparent;
  border: 1px solid @blue;
  border-radius: 6px;
  color: @blue;

  &:hover,
  &:focus {
    background-color: @light-grey;
    color: @blue;
  }
}

.ui.button.icon.iconSort {
  display: flex;
  align-items: center;
  background: transparent;
  color: @ref-color-grey;
  padding: 0;

  .icon {
    opacity: 1;
    height: 10px;
    position: relative;

    &:before {
      font-size: 21px;
      position: absolute;
      top: calc(50% - 16px);
      left: 1px;
      right: unset;
      bottom: unset;
    }
  }
}
