/*******************************
         Theme Overrides
*******************************/

.ui.form .field {
  margin-bottom: 0;
}

.field .divider > .field {
  margin-bottom: 0 !important;
}

.required {
  color: @ref-color-red;
}

.ui.search {
  width: 100%;

  * {
    width: inherit;
  }

  input {
    border-radius: 6px !important;
    border: 0 !important;
    padding: 5px !important;
    box-shadow: @ref-box-shadow !important;
  }
}

label.labelSmall,
.ui.label.labelSmall {
  display: block;
  margin: 0 0 5px 0;
  padding: 0;
  background: transparent;
  color: @fullBlack;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 18px;
  text-align: left;
  width: 100%;

  i.info {
    margin: 0 5px 0 0;
  }
}

.field .ui.input {
  width: 100%;
}

.ui.disabled.input, .ui.input:not(.disabled) input[disabled] {
  opacity: 0.65;
  box-shadow: inset 0px 0px 1px 1px @border-grey;
  border-radius: 6px;
}

input[type="text"],
input[type="strung"],
input[type="email"],
input[type="search"],
input[type="number"],
input[type="date"],
input[type="password"],
textarea {
  width: 100%;
  border: none;
  border-radius: 6px;
  min-height: 26px;
  padding: 5px 10px;
  color: @dark-grey;
  line-height: 16px;

  &:disabled {
    background-color: transparent;
  }

  &:focus-visible {
    outline: 1px dotted @ref-color-underline !important;
  }
}

.field input[type="text"],
.field input[type="email"],
.field input[type="search"],
.field input[type="number"],
.field input[type="date"],
.field input[type="password"],
.field textarea {
  width: 100% !important;
  border: none !important;
  background: @light-grey !important;
  border-radius: 6px;
  min-height: 26px !important;
  padding: 5px 10px !important;
  color: @dark-grey !important;
  line-height: 16px !important;

  &:disabled {
    background-color: transparent;
  }

  &:focus-visible {
    // outline: 1px dotted @ref-color-underline !important;
  }
}

.field.error input[type="text"],
.field.error input[type="email"],
.field.error input[type="search"],
.field.error input[type="number"],
.field.error input[type="date"],
.field.error input[type="password"],
.field.error textarea {
  box-shadow: inset 0 0 4px rgba(255, 0, 0, 0.7) !important;
}

.field input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.disabled.field {
  background-color: transparent;
  opacity: 0.65 !important;
  color: #000000 !important;
  box-shadow: inset 0px 0px 1px 1px @border-grey;
  border-radius: 6px;

  & * {
    color: inherit !important;
    opacity: inherit !important;
    background-color: inherit !important;
  }

  .icon {
    display: none;
  }
}

.disabled input {
  color: inherit !important;
  opacity: inherit !important;
  background-color: inherit !important;
}

.field textarea {
  resize: none;
  height: 76px;
}

.field .icon.info {
  color: #6C84A6;
  width: 16px;
  height: 16px;
  flex: 1 1 16px;
  font-size: 15px;
  margin-right: 5px;
}

.field > .label,
.field > h1,
.field > h2,
.field > h3,
.field > h4,
.field > h5,
.field > h6,
.field > .h1,
.field > .h2,
.field > .h3,
.field > .h4,
.field > .h5,
.field > .h6 {
  margin-bottom: 9px;
}

.fieldGrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 20px;

  @media (min-width: @breakpoint-mobile-horizontal) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 0px;
  }
}

.fieldGridSlider {

  label.labelSmall {
    margin-right: 0;
    text-align: left;
  }

  .fieldSlider {
    margin-top: 10px;
  }

  @media (min-width: @breakpoint-mobile-horizontal) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 0;
    align-items: end;

    .fieldSlider {
      margin-top: 0;
      margin-bottom: 6px;
    }
  }

  @media (min-width: @breakpoint-tablet-horizontal) {
    grid-template-columns: repeat(4, 1fr);

    & > :first-child {
      grid-column: 1 / span 1;
    }

    & > :last-child {
      grid-column: 2 / span 3;
    }
  }


}



