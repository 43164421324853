/*******************************
         Theme Overrides
*******************************/


.ui.page.dimmer.modals.active {
  z-index: 1002;
}

.ui.inverted.dimmer > .ui.modal {
  background: transparent;
  box-shadow: none;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 35px;
    height: 7%;
    width: 100%;
    transform: skewX(300deg);
    background: #D8D8D8;
  }
}

.ui.page.dimmer.modals > .content {
  padding: 60px 70px 20px;
}

.ui.page.dimmer.modals .modal > .header,
.ui.page.dimmer.modals .modal > .content,
.ui.page.dimmer.modals .modal > .actions {
  padding-left: 70px;
  padding-right: 70px;
  background-color: #EEEEEE;
  border: none;
  border-radius: 0;
}

.ui.page.dimmer.modals .modal > .header + .content {
  padding: 0 70px 26px;
}

.ui.page.dimmer.modals .modal > .header {
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-size: 25px;
  font-weight: 400;
  line-height: 1.3;
  text-transform: uppercase;
  padding-bottom: 22px;
  position: relative;

  &:before {
    content: '';
    width: calc(100% - 140px);
    height: 1px;
    background-color: #333333;
    position: absolute;
    bottom: 17px;
    left: 70px;
  }
}


.ui.page.dimmer.modals .modal > .actions {
  position: relative;

  &:before {
    content: '';
    width: calc(100% - 140px);
    height: 1px;
    background-color: #333333;
    position: absolute;
    top: 0px;
    left: 70px;
  }
}


