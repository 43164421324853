/*******************************
         Theme Overrides
*******************************/
@font-face {
  font-family: 'Accordion';
  src: url(data:application/x-font-ttf;charset=utf-8;base64,AAEAAAALAIAAAwAwT1MvMggjB5AAAAC8AAAAYGNtYXAPfOIKAAABHAAAAExnYXNwAAAAEAAAAWgAAAAIZ2x5Zryj6HgAAAFwAAAAyGhlYWT/0IhHAAACOAAAADZoaGVhApkB5wAAAnAAAAAkaG10eAJuABIAAAKUAAAAGGxvY2EAjABWAAACrAAAAA5tYXhwAAgAFgAAArwAAAAgbmFtZfC1n04AAALcAAABPHBvc3QAAwAAAAAEGAAAACAAAwIAAZAABQAAAUwBZgAAAEcBTAFmAAAA9QAZAIQAAAAAAAAAAAAAAAAAAAABEAAAAAAAAAAAAAAAAAAAAABAAADw2gHg/+D/4AHgACAAAAABAAAAAAAAAAAAAAAgAAAAAAACAAAAAwAAABQAAwABAAAAFAAEADgAAAAKAAgAAgACAAEAIPDa//3//wAAAAAAIPDZ//3//wAB/+MPKwADAAEAAAAAAAAAAAAAAAEAAf//AA8AAQAAAAAAAAAAAAIAADc5AQAAAAABAAAAAAAAAAAAAgAANzkBAAAAAAEAAAAAAAAAAAACAAA3OQEAAAAAAQASAEkAtwFuABMAADc0PwE2FzYXFh0BFAcGJwYvASY1EgaABQgHBQYGBQcIBYAG2wcGfwcBAQcECf8IBAcBAQd/BgYAAAAAAQAAAEkApQFuABMAADcRNDc2MzIfARYVFA8BBiMiJyY1AAUGBwgFgAYGgAUIBwYFWwEACAUGBoAFCAcFgAYGBQcAAAABAAAAAQAAqWYls18PPPUACwIAAAAAAM/9o+4AAAAAz/2j7gAAAAAAtwFuAAAACAACAAAAAAAAAAEAAAHg/+AAAAIAAAAAAAC3AAEAAAAAAAAAAAAAAAAAAAAGAAAAAAAAAAAAAAAAAQAAAAC3ABIAtwAAAAAAAAAKABQAHgBCAGQAAAABAAAABgAUAAEAAAAAAAIAAAAAAAAAAAAAAAAAAAAAAAAADgCuAAEAAAAAAAEADAAAAAEAAAAAAAIADgBAAAEAAAAAAAMADAAiAAEAAAAAAAQADABOAAEAAAAAAAUAFgAMAAEAAAAAAAYABgAuAAEAAAAAAAoANABaAAMAAQQJAAEADAAAAAMAAQQJAAIADgBAAAMAAQQJAAMADAAiAAMAAQQJAAQADABOAAMAAQQJAAUAFgAMAAMAAQQJAAYADAA0AAMAAQQJAAoANABaAHIAYQB0AGkAbgBnAFYAZQByAHMAaQBvAG4AIAAxAC4AMAByAGEAdABpAG4AZ3JhdGluZwByAGEAdABpAG4AZwBSAGUAZwB1AGwAYQByAHIAYQB0AGkAbgBnAEYAbwBuAHQAIABnAGUAbgBlAHIAYQB0AGUAZAAgAGIAeQAgAEkAYwBvAE0AbwBvAG4ALgADAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA) format('truetype'),
  url(data:application/font-woff;charset=utf-8;base64,d09GRk9UVE8AAASwAAoAAAAABGgAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABDRkYgAAAA9AAAAS0AAAEtFpovuE9TLzIAAAIkAAAAYAAAAGAIIweQY21hcAAAAoQAAABMAAAATA984gpnYXNwAAAC0AAAAAgAAAAIAAAAEGhlYWQAAALYAAAANgAAADb/0IhHaGhlYQAAAxAAAAAkAAAAJAKZAedobXR4AAADNAAAABgAAAAYAm4AEm1heHAAAANMAAAABgAAAAYABlAAbmFtZQAAA1QAAAE8AAABPPC1n05wb3N0AAAEkAAAACAAAAAgAAMAAAEABAQAAQEBB3JhdGluZwABAgABADr4HAL4GwP4GAQeCgAZU/+Lix4KABlT/4uLDAeLa/iU+HQFHQAAAHkPHQAAAH4RHQAAAAkdAAABJBIABwEBBw0PERQZHnJhdGluZ3JhdGluZ3UwdTF1MjB1RjBEOXVGMERBAAACAYkABAAGAQEEBwoNVp38lA78lA78lA77lA773Z33bxWLkI2Qj44I9xT3FAWOj5CNkIuQi4+JjoePiI2Gi4YIi/uUBYuGiYeHiIiHh4mGi4aLho2Ijwj7FPcUBYeOiY+LkAgO+92L5hWL95QFi5CNkI6Oj4+PjZCLkIuQiY6HCPcU+xQFj4iNhouGi4aJh4eICPsU+xQFiIeGiYaLhouHjYePiI6Jj4uQCA74lBT4lBWLDAoAAAAAAwIAAZAABQAAAUwBZgAAAEcBTAFmAAAA9QAZAIQAAAAAAAAAAAAAAAAAAAABEAAAAAAAAAAAAAAAAAAAAABAAADw2gHg/+D/4AHgACAAAAABAAAAAAAAAAAAAAAgAAAAAAACAAAAAwAAABQAAwABAAAAFAAEADgAAAAKAAgAAgACAAEAIPDa//3//wAAAAAAIPDZ//3//wAB/+MPKwADAAEAAAAAAAAAAAAAAAEAAf//AA8AAQAAAAEAADfYOJZfDzz1AAsCAAAAAADP/aPuAAAAAM/9o+4AAAAAALcBbgAAAAgAAgAAAAAAAAABAAAB4P/gAAACAAAAAAAAtwABAAAAAAAAAAAAAAAAAAAABgAAAAAAAAAAAAAAAAEAAAAAtwASALcAAAAAUAAABgAAAAAADgCuAAEAAAAAAAEADAAAAAEAAAAAAAIADgBAAAEAAAAAAAMADAAiAAEAAAAAAAQADABOAAEAAAAAAAUAFgAMAAEAAAAAAAYABgAuAAEAAAAAAAoANABaAAMAAQQJAAEADAAAAAMAAQQJAAIADgBAAAMAAQQJAAMADAAiAAMAAQQJAAQADABOAAMAAQQJAAUAFgAMAAMAAQQJAAYADAA0AAMAAQQJAAoANABaAHIAYQB0AGkAbgBnAFYAZQByAHMAaQBvAG4AIAAxAC4AMAByAGEAdABpAG4AZ3JhdGluZwByAGEAdABpAG4AZwBSAGUAZwB1AGwAYQByAHIAYQB0AGkAbgBnAEYAbwBuAHQAIABnAGUAbgBlAHIAYQB0AGUAZAAgAGIAeQAgAEkAYwBvAE0AbwBvAG4ALgADAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA) format('woff')
;
  font-weight: normal;
  font-style: normal;
}

/* Dropdown Icon */
.ui.accordion .title .dropdown.icon,
.ui.accordion .accordion .title .dropdown.icon {
  font-family: Accordion;
  line-height: 1;
  backface-visibility: hidden;
  font-weight: normal;
  font-style: normal;
  text-align: center;
}

.ui.accordion .title .dropdown.icon:before,
.ui.accordion .accordion .title .dropdown.icon:before {
  content: '\f0da' /*rtl:'\f0d9'*/;
}

.ui.accordion .title,
.ui.accordion .title:not(.ui) {
  position: relative;
  border-bottom: 1px solid @ref-color-underline;
  display: flex;
  flex-direction: row-reverse;
  justify-content: left;
  align-items: center;
  padding-bottom: 5px;
  padding-right: 22px;
  padding-top: 0;
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 10px;

  &:before {
    content: "\f105";
    position: absolute;
    right: -8px;
    display: inline-block;
    opacity: 1;
    margin: 0;
    width: 1.18em;
    height: 1em;
    font-family: 'Icons';
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    text-align: center;
    speak: none;
    font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    transition: transform 300ms ease;
  }

  span {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    line-height: 25px;

    svg:not(.redIcon) {
      margin: 0 10px;
      max-width: 18px;
      height: 16px;
      margin-left: 15px;
    }

    .redIcon {
      background: #DD2727;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      color: #fff;
      padding: 4px;
      box-sizing: border-box;
      margin-left: 15px;
    }
  }
}


.ui.accordion .title.active {
  &:before {
    transform: rotate(90deg);
  }
}

.ui.accordion > .title:not(:first-child) {
  margin-top: 30px;
}

// Primary Accordion
.ui.accordion.accordionPrimary {
  background-color: @white !important;

  & + .accordionPrimary {
    margin-top: 10px;
  }

  & > .title:not(:first-child) {
    margin-top: 10px;
  }

  & > .title {
    min-height: 30px;
    white-space: nowrap;
    overflow: hidden;
    background: @blue;
    border: 0;
    border-radius: 6px;
    padding: 0 10px 0 40px;
    color: @white;
    font-size: 13.5px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    line-height: 19px;
    z-index: 1;

    span {
      font-size: inherit;
    }

    span:first-child {
      font-weight: 700;
      padding: 0 20px 0 8px;
      margin: 0 20px 0 0;
      display: inline-block;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        display: block;
        width: 1px;
        height: 60%;
        top: 20%;
        right: 0;
        background: @ref-color-grey;
      }
    }

    span:last-child {
      padding-left: 5px;
      font-weight: 700;
    }

    &:before {
      font-size: 16px;
      right: 10px;
      top: 6px;
    }
  }
}

.movableList {

  .ui.accordion.accordionPrimary {

    .title ~ .content {
      background-color: transparent;
      transition: background-color 0.3s ease;
    }

    .title ~ .content:not(.ui) {
      padding: 0;
    }

    .title ~ .content.active {
      margin-bottom: -10px;
    }
  }
}


body > .movableItem {

  .ui.accordion.accordionPrimary {
    .title ~ .content.active {
      margin-bottom: 0;
      background-color: @blue;

      * {
        visibility: hidden;
        opacity: 0;
      }
    }
  }
}
