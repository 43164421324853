/*******************************
         Theme Overrides
*******************************/

@font-face {
  font-family: 'Checkbox';
  src: url(data:application/x-font-ttf;charset=utf-8;base64,AAEAAAALAIAAAwAwT1MvMg8SBD8AAAC8AAAAYGNtYXAYVtCJAAABHAAAAFRnYXNwAAAAEAAAAXAAAAAIZ2x5Zn4huwUAAAF4AAABYGhlYWQGPe1ZAAAC2AAAADZoaGVhB30DyAAAAxAAAAAkaG10eBBKAEUAAAM0AAAAHGxvY2EAmgESAAADUAAAABBtYXhwAAkALwAAA2AAAAAgbmFtZSC8IugAAAOAAAABknBvc3QAAwAAAAAFFAAAACAAAwMTAZAABQAAApkCzAAAAI8CmQLMAAAB6wAzAQkAAAAAAAAAAAAAAAAAAAABEAAAAAAAAAAAAAAAAAAAAABAAADoAgPA/8AAQAPAAEAAAAABAAAAAAAAAAAAAAAgAAAAAAADAAAAAwAAABwAAQADAAAAHAADAAEAAAAcAAQAOAAAAAoACAACAAIAAQAg6AL//f//AAAAAAAg6AD//f//AAH/4xgEAAMAAQAAAAAAAAAAAAAAAQAB//8ADwABAAAAAAAAAAAAAgAANzkBAAAAAAEAAAAAAAAAAAACAAA3OQEAAAAAAQAAAAAAAAAAAAIAADc5AQAAAAABAEUAUQO7AvgAGgAAARQHAQYjIicBJjU0PwE2MzIfAQE2MzIfARYVA7sQ/hQQFhcQ/uMQEE4QFxcQqAF2EBcXEE4QAnMWEP4UEBABHRAXFhBOEBCoAXcQEE4QFwAAAAABAAABbgMlAkkAFAAAARUUBwYjISInJj0BNDc2MyEyFxYVAyUQEBf9SRcQEBAQFwK3FxAQAhJtFxAQEBAXbRcQEBAQFwAAAAABAAAASQMlA24ALAAAARUUBwYrARUUBwYrASInJj0BIyInJj0BNDc2OwE1NDc2OwEyFxYdATMyFxYVAyUQEBfuEBAXbhYQEO4XEBAQEBfuEBAWbhcQEO4XEBACEm0XEBDuFxAQEBAX7hAQF20XEBDuFxAQEBAX7hAQFwAAAQAAAAIAAHRSzT9fDzz1AAsEAAAAAADRsdR3AAAAANGx1HcAAAAAA7sDbgAAAAgAAgAAAAAAAAABAAADwP/AAAAEAAAAAAADuwABAAAAAAAAAAAAAAAAAAAABwQAAAAAAAAAAAAAAAIAAAAEAABFAyUAAAMlAAAAAAAAAAoAFAAeAE4AcgCwAAEAAAAHAC0AAQAAAAAAAgAAAAAAAAAAAAAAAAAAAAAAAAAOAK4AAQAAAAAAAQAIAAAAAQAAAAAAAgAHAGkAAQAAAAAAAwAIADkAAQAAAAAABAAIAH4AAQAAAAAABQALABgAAQAAAAAABgAIAFEAAQAAAAAACgAaAJYAAwABBAkAAQAQAAgAAwABBAkAAgAOAHAAAwABBAkAAwAQAEEAAwABBAkABAAQAIYAAwABBAkABQAWACMAAwABBAkABgAQAFkAAwABBAkACgA0ALBDaGVja2JveABDAGgAZQBjAGsAYgBvAHhWZXJzaW9uIDIuMABWAGUAcgBzAGkAbwBuACAAMgAuADBDaGVja2JveABDAGgAZQBjAGsAYgBvAHhDaGVja2JveABDAGgAZQBjAGsAYgBvAHhSZWd1bGFyAFIAZQBnAHUAbABhAHJDaGVja2JveABDAGgAZQBjAGsAYgBvAHhGb250IGdlbmVyYXRlZCBieSBJY29Nb29uLgBGAG8AbgB0ACAAZwBlAG4AZQByAGEAdABlAGQAIABiAHkAIABJAGMAbwBNAG8AbwBuAC4AAAADAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA) format('truetype')
;
}


/* Checkmark */

.ui.checkbox {
  display: flex;
}

.ui.checkbox label:after,
.ui.checkbox .box:after {
  font-family: 'Checkbox';
}

/* Checked */
.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
  content: '\e800';
}

/* Indeterminate */
.ui.checkbox input:indeterminate ~ .box:after,
.ui.checkbox input:indeterminate ~ label:after {
  font-size: 12px;
  content: '\e801';
}

.ui.checkbox .box:after,
.ui.checkbox label:after {
  width: 15px;
  height: 15px;
  font-size: 12px;
}

.ui.checkbox .box:before,
.ui.checkbox label:before,
.ui.radio.checkbox .box:before,
.ui.radio.checkbox label:before {
  width: 15px;
  height: 15px;
  border: none;
  background-color: @light-grey;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

.ui.checkbox + .map {
  position: relative;

  &:after {
    transition: opacity 300ms linear;
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: all;
    opacity: 0.2;
    background-color: @dark-grey;
  }
}

.ui.checked.checkbox + .map {
  &:after {
    pointer-events: none;
    opacity: 0;
  }
}


.ui.fitted.toggle.checkbox,
.ui.fitted.toggle.checkbox {
  width: auto;
  min-width: auto;
  height: auto;
  min-height: auto;
}

div.ui.toggle.checkbox input ~ .box,
div.ui.toggle.checkbox input ~ label {
  min-height: auto;
  width: 22px;
  height: 12px;
}

div.ui.toggle.checkbox input ~ .box:before,
div.ui.toggle.checkbox input ~ label:before {
  background-color: @blue !important;
  width: 100%;
  height: 100%;
}

div.ui.toggle.checkbox input:checked ~ .box:before,
div.ui.toggle.checkbox input:checked ~ label:before {
  background-color: @blue !important;
  width: 100%;
  height: 100%;
}

.disabled div.ui.toggle.checkbox input ~ .box:before,
.disabled div.ui.toggle.checkbox input ~ label:before,
.disabled div.ui.toggle.checkbox input:checked ~ .box:before,
.disabled div.ui.toggle.checkbox input:checked ~ label:before {
  background-color: #000 !important;
}

div.ui.toggle.checkbox input ~ .box:after,
div.ui.toggle.checkbox input ~ label:after,
div.ui.toggle.checkbox input:checked ~ .box:after,
div.ui.toggle.checkbox input:checked ~ label:after {
  box-shadow: none;
  width: 8px;
  height: 8px;
  top: 2px;
}

div.ui.toggle.checkbox input ~ .box:after,
div.ui.toggle.checkbox input ~ label:after {
  left: 2px;
}

div.ui.toggle.checkbox input:checked ~ .box:after,
div.ui.toggle.checkbox input:checked ~ label:after {
  left: calc(100% - 10px);
}


/* Colored Checkbox */
div.ui.toggle.checkbox.colored-checkbox input:checked ~ .box:before,
div.ui.toggle.checkbox.colored-checkbox input:checked ~ label:before {
  background-color: @blue !important;
  width: 100%;
  height: 100%;
}

/* Large Checkbox */
div.ui.toggle.checkbox.large-checkbox input ~ .box,
div.ui.toggle.checkbox.large-checkbox input ~ label {
  min-height: auto;
  width: 52px;
  height: 24px;
}

div.ui.toggle.checkbox.large-checkbox input ~ .box:after,
div.ui.toggle.checkbox.large-checkbox input ~ label:after,
div.ui.toggle.checkbox.large-checkbox input:checked ~ .box:after,
div.ui.toggle.checkbox.large-checkbox input:checked ~ label:after {
  width: 20px;
  height: 20px;
}

div.ui.toggle.checkbox.large-checkbox input:checked ~ .box:after,
div.ui.toggle.checkbox.large-checkbox input:checked ~ label:after {
  left: calc(100% - 22px);
}


/*  UTF Reference
.check:before { content: '\e800'; }
.dash:before  { content: '\e801'; }
.plus:before { content: '\e802'; }
*/
