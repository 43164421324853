/*******************************
         Theme Overrides
*******************************/

/* Dropdown Carets */
@font-face {
  font-family: 'Dropdown';
  src: url(data:application/font-woff;charset=utf-8;base64,d09GRgABAAAAAAVgAA8AAAAACFAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABGRlRNAAABWAAAABwAAAAchGgaq0dERUYAAAF0AAAAHAAAAB4AJwAPT1MvMgAAAZAAAABDAAAAVnW4TJdjbWFwAAAB1AAAAEsAAAFS8CcaqmN2dCAAAAIgAAAABAAAAAQAEQFEZ2FzcAAAAiQAAAAIAAAACP//AANnbHlmAAACLAAAAQoAAAGkrRHP9WhlYWQAAAM4AAAAMAAAADYPK8YyaGhlYQAAA2gAAAAdAAAAJANCAb1obXR4AAADiAAAACIAAAAiCBkAOGxvY2EAAAOsAAAAFAAAABQBnAIybWF4cAAAA8AAAAAfAAAAIAEVAF5uYW1lAAAD4AAAATAAAAKMFGlj5HBvc3QAAAUQAAAARgAAAHJoedjqd2ViZgAABVgAAAAGAAAABrO7W5UAAAABAAAAANXulPUAAAAA1r4hgAAAAADXu2Q1eNpjYGRgYOABYjEgZmJgBEIOIGYB8xgAA/YAN3jaY2BktGOcwMDKwMI4jTGNgYHBHUp/ZZBkaGFgYGJgZWbACgLSXFMYHFT/fLjFeOD/AQY9xjMMbkBhRpAcAN48DQYAeNpjYGBgZoBgGQZGBhDwAfIYwXwWBgMgzQGETAwMqn8+8H649f8/lHX9//9b7Pzf+fWgusCAkY0BzmUE6gHpQwGMDMMeAACbxg7SAAARAUQAAAAB//8AAnjadZBPSsNAGMXfS+yMqYgOhpSuSlKadmUhiVEhEMQzFF22m17BbbvzCh5BXCUn6EG8gjeQ4DepwYo4i+/ffL95j4EDA+CFC7jQuKyIeVHrI3wkleq9F7XrSInKteOeHdda8bOoaeepSc00NWPz/LRec9G8GabyGtEdF7h19z033GAMTK7zbM42xNEZpzYof0RtQ5CUHAQJ73OtVyutc+3b7Ou//b8XNlsPx3jgjUifABdhEohKJJL5iM5p39uqc7X1+sRQSqmGrUVhlsJ4lpmEUVwyT8SUYtg0P9DyNzPADDs+tjrGV6KRCRfsui3eHcL4/p8ZXvfMlcnEU+CLv7hDykOP+AKTPTxbAAB42mNgZGBgAGKuf5KP4vltvjLIMzGAwLV9ig0g+vruFFMQzdjACOJzMIClARh0CTJ42mNgZGBgPPD/AJD8wgAEjA0MjAyogAMAbOQEAQAAAAC7ABEAAAAAAKoAAAH0AAABgAAAAUAACAFAAAgAwAAXAAAAAAAAACoAKgAqADIAbACGAKAAugDSeNpjYGRgYOBkUGFgYgABEMkFhAwM/xn0QAIADdUBdAB42qWQvUoDQRSFv3GjaISUQaymSmGxJoGAsRC0iPYLsU50Y6IxrvlRtPCJJKUPIBb+PIHv4EN4djKuKAqCDHfmu+feOdwZoMCUAJNbAlYUMzaUlM14jjxbngOq7HnOia89z1Pk1vMCa9x7ztPkzfMyJbPj+ZGi6Xp+omxuPD+zaD7meaFg7mb8GrBqHmhwxoAxlm0uiRkpP9X5m26pKRoMxTGR1D49Dv/Yb/91o6l8qL6eu5n2hZQzn68utR9m3FU2cB4t9cdSLG2utI+44Eh/P9bqKO+oJ/WxmXssj77YkrjasZQD6SFddythk3Wtzrf+UF2p076Udla1VNzsERP3kkjVRKel7mp1udXYcHtZSlV7RfmJe1GiFWveluaeKD5/MuJcSk8Tpm/vvwPIbmJleNpjYGKAAFYG7ICTgYGRiZGZkYWRlZGNkZ2Rg5GTLT2nsiDDEEIZsZfmZRqZujmDaDcDAxcI7WIOpS2gtCWUdgQAZkcSmQAAAAFblbO6AAA=) format('woff')
;
  font-weight: normal;
  font-style: normal;
}


.ui.selection.dropdown,
.ui.multiple.dropdown {
  border: none !important;
  border-radius: 0 !important;
  min-height: 26px !important;
  padding: 5px 25px 5px 5px !important;
}

.ui.selection.dropdown {
  min-width: 8em !important;
  width: 100%;
  background-color: @light-grey;
  font-size: 12px;
  border-radius: 6px !important;
}

.ui.selection.dropdown,
.ui.multiple.search.dropdown {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;

  & > input.search {
    margin: 0;
  }
}


.ui.selection.dropdown .menu {
  border: none !important;
  border-radius: 6px !important;
  margin: 0 !important;
  min-width: 100% !important;
  width: 100% !important;
}


.ui.dropdown > .dropdown.icon {
  font-family: 'Dropdown';
  line-height: 1;
  height: 1em;
  width: 2em !important;
  backface-visibility: hidden;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  background-color: @blue;
  color: @white;
  border-radius: 6px;
}

.ui.dropdown > .dropdown.icon {
  width: auto;
}

.ui.dropdown > .dropdown.icon:before {
  content: '\f0d7';
}

/* Sub Menu */
.ui.dropdown .menu .item .dropdown.icon:before {
  content: '\f0da' /*rtl:'\f0d9'*/;
}

.ui.dropdown .item .left.dropdown.icon:before,
.ui.dropdown .left.menu .item .dropdown.icon:before {
  content: "\f0d9" /*rtl:"\f0da"*/;
}

/* Vertical Menu Dropdown */
.ui.vertical.menu .dropdown.item > .dropdown.icon:before {
  content: "\f0da" /*rtl:"\f0d9"*/;
}

.ui.dropdown > .clear.icon:before {
  content: "\f00d";
}

.ui.form .field > .selection.dropdown {
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
}

.ui.form .field > .selection.dropdown .menu > .item {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}


.ui.form .field > .selection.dropdown.dropdownTags {
  min-height: 60px !important;
}

.ui.multiple.dropdown > .label {
  display: flex;
  align-items: center;
  box-shadow: none;
  background: @grey;
  border: none;
  border-radius: 6px;
  font-size: 11px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  padding: 5px 10px;
  color: @white;
  width: auto;
}

.ui.label > .close.icon, .ui.label > .delete.icon {
  color: @white;
}

.disabled.field .disabled.dropdown {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3) !important;
  width: 100% !important;
  border: none !important;
  border-radius: unset !important;
  min-height: 26px !important;
  line-height: 16px !important;
  padding: 5px 10px !important;
}

.field .dropdown .text {
  margin: 0 !important;
}

.field .dropdown.icon {
  padding: 6px !important;
}

/* Icons for Reference (Subsetted in 2.4.0)
  .dropdown.down:before { content: "\f0d7"; }
  .dropdown.up:before { content: "\f0d8"; }
  .dropdown.left:before { content: "\f0d9"; }
  .dropdown.right:before { content: "\f0da"; }
  .dropdown.close:before { content: "\f00d"; }
*/

